<template>
  <div id="header" class="ss fixed-top header-transparent header-custom">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo me-auto">
        <h1>
          <a href="/"><Logo class="pike-brand-logo" /></a>
        </h1>
      </div>
      <b-navbar toggleable="lg" class="order-last order-lg-0">
        <Locale class="lang" />
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto mobile-menu">
            <li>
              <a :href="dynamicUrlLogin" class="scrollto">{{ $t("Login") }}</a>
            </li>
            <li>
              <a :href="dynamicUrlRegister" class="scrollto"
                ><div class="login-btn scrollto">
                  {{ $t("SignUp") }}
                </div></a
              >
            </li>
            <!-- <li></li> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import Locale from "@/layouts/components/Locale.vue";
import Logo from "@/assets/rashiCoin/logo.svg";
export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    Locale,
    BRow,
    BCol,
    BCard,
    BCardText,
    Logo
  },
  data() {
    return {
      isActive: 1,
      lang: this.$route.params.lang,
      dynamicUrlLogin: "https://dev.pikeswop.com/auth/login",
      dynamicUrlRegister: "https://dev.pikeswop.com/auth/register"
    };
  },
  mounted() {
    if (process.env.VUE_APP_NODE_ENV === "production") {
      this.dynamicUrlLogin = "https://app.pikeswop.com/auth/login";
      this.dynamicUrlRegister = "https://app.pikeswop.com/auth/register";
    } else {
      this.dynamicUrlLogin = "https://dev.pikeswop.com/auth/login";
      this.dynamicUrlRegister = "https://dev.pikeswop.com/auth/register";
    }
  },
  methods: {
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>
