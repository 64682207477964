<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="footer-info">
              <p>
                {{ $t("Copyright") }}
              </p>
              <div class="social-links mt-3">
                <a href="https://x.com/pikeswop" class="twitter"
                  ><i class="bx bxl-twitter"></i
                ></a>
                <a href="https://www.facebook.com/pikeswop" class="facebook"
                  ><i class="bx bxl-facebook"></i
                ></a>
                <a
                  href="https://www.pinterest.com/pikeswop/_self-profile/"
                  class="instagram"
                  ><i class="bx bxl-pinterest"></i
                ></a>
                <a
                  href="https://www.reddit.com/user/pikeswop/"
                  class="google-plus"
                  ><i class="bx bxl-reddit"></i
                ></a>
                <a
                  href="https://www.linkedin.com/company/102561383/admin/feed/posts/"
                  class="linkedin"
                  ><i class="bx bxl-linkedin"></i
                ></a>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>{{ $t("Company") }}</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a @click="openPage(`aboutUs`)">{{ $t("AboutUs") }}</a>
              </li>
              <!-- <li>
                <i class="bx bx-chevron-right"></i>
                <a @click="openPage(`support`)">{{ $t("ContactUs1") }}</a>
              </li> -->
              <li>
                <i class="bx bx-chevron-right"></i>
                <a @click="openPage(`termsOfService`)">{{
                  $t("TermsOfService")
                }}</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a @click="openPage(`legal`)">{{ $t("Legal") }}</a>
              </li>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a @click="openPage(`privacyPolicy`)">{{
                  $t("PrivacyPolicy")
                }}</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>{{ $t("Support") }}</h4>
            <ul>
              <li>
                <i class="bx bx-chevron-right"></i>
                <a
                  href="https://support.pikeswop.com/hc/en-us/requests/new"
                  target="_blank"
                  >{{ $t("HelpCenter") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import {
  BLink,
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
export default {
  components: {
    BLink,
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    BRow,
    BCol,
    BCard,
    BCardText
  },
  data() {
    return {
      lang: this.$route.params.lang
    };
  },
  mounted() {},
  methods: {
    redirectToContact() {
      if (this.$route.path !== "/" && this.$route.path !== "/home") {
        this.$router.push("/");
      }
    },
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  }
};
</script>
<style lang="scss">
@import "../assets/landing/vendor/bootstrap/css/bootstrap.min.css";
@import "../assets/landing/vendor/bootstrap-icons/bootstrap-icons.css";
@import "../assets/landing/vendor/boxicons/css/boxicons.min.css";
@import "../assets/landing/vendor/glightbox/css/glightbox.min.css";
@import "../assets/landing/vendor/swiper/swiper-bundle.min.css";
@import "../assets/landing/css/style.scss";
</style>
